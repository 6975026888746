@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.App {
  /* text-align: center; */
  /* padding-top: 90px; */
  font-family: "Montserrat", sans-serif;
  zoom: 90%;
}
.nav-item {
  font-family: "Montserrat", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.step {
  border-left: #ff4444;
  border-left-style: solid;
  border-width: 0.6mm;
  padding-left: 10px;
}
.upload,
.grns,
.asns {
  height: 100vh;
}
.nounderline {
  text-decoration: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*  */

.fixed-button {
  background-color: #045e84;
  color: #feac00;
  border: none;
  outline: none;
  position: fixed;
  bottom: 0px;
  padding: 10px 35px;
  right: 50px;
  height: auto;
  width: auto;
  margin-bottom: 30px;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 1px 3px 5px #888888;
}

@keyframes wobble {
  16.65% {
    -webkit-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    -ms-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.navbar {
  /* box-shadow: 0 7px 10px -8px #000; */

  /* the rest of your styling */
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

body.modal-open {
  overflow: auto;
}
.modal.in {
  pointer-events: none;
}
.modal-content {
  pointer-events: all;
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;

  width: 100%;
  height: 100%;
}

/* Landing Page */
.landing {
  position: relative;
  background: url("./img/login-bg.jpeg") no-repeat center center/cover;
  height: 112vh;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing-inner {
  margin-top: 70px;
  color: #fff;
  height: 100%;
  width: 100%;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.status {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  height: 120vh;
}
.status-overlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.status-inner {
  margin-top: 120px;
  height: 100%;
  width: 100%;
}
.login {
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: -50px;
}
input.transparent-input {
  background-color: transparent !important;
  color: #fff !important;
}

input.transparent-input::placeholder {
  color: #ccc;
  opacity: 0.8;
}

.transparent-btn {
  background-color: transparent;
  color: #fff;
}

.transparent {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: #fff;
}
td {
  font-size: 13px;
}

th {
  font-size: 13px;
}
.text-primary {
  color: #045e84 !important;
}

.btn-primary {
  background-color: #045e84 !important;
  border: none !important;
}

.bg-primary {
  background-color: #045e84 !important;
}

.track-main {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  height: 120vh;
  padding-top: 60px;
}

.feedback {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  /* animation: gradient 20s ease infinite; */
  height: 120vh;
  padding-top: 100px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.footer {
  position: fixed;
  height: 50px;
  /* background-color: #f5f5f5; */
  color: #fff;
  padding-top: 20px;
  padding-right: 20px;
  text-align: center;
  bottom: 10px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.fab {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 60px;
  right: 100px;
  background: linear-gradient(-45deg, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #000;
}

.fab:hover {
  background-color: #045e84;
  color: #fff;
  width: 61px;
  height: 61px;
}

.my-fab {
  margin-top: 22px;
}

.tableFixHead {
  overflow: auto;
  height: 100vh;
  width: 100%;
}
.tableFixHead thead th {
  background-color: #045e84;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */

th,
td {
  text-align: center;
}
